<template>
  <div class="container" ref="container">
  <div style="display: flex;flex-direction:column;justify-content:space-between;
  width:100%;min-width:300px;padding:10px;" :style="'max-width:'+divwidth+'px;'">
  <el-card style="margin-top:0px;" shadow1="never">
    <el-descriptions  title="账户信息" :column="2" :size="size" border 
    label-class-name="my-label" content-class-name="my-content">
    <template slot="extra">
      <el-form size="mini" :inline="true" >
        <el-button type="success" size="medium" icon="el-icon-mobile-phone" plain @click="showbandMobile = true" 
          v-if="LoginInfo.login_source!='密码'">绑手机</el-button>
        <el-button type="success" size="medium" icon="el-icon-connection" plain @click="bandWeixin" 
          v-if="LoginInfo.login_source=='密码'">绑微信</el-button>
        <el-button type="success" size="medium" icon="el-icon-s-goods" plain @click="showeditPass = true" 
          v-if="LoginInfo.Mobile">修改密码</el-button>
      </el-form>
    </template>
    <el-descriptions-item >
      <template slot="label">
        <i class="el-icon-user"></i>
        登录
      </template>
      {{LoginInfo.login_source}}
      </el-descriptions-item>
      <el-descriptions-item >
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        {{LoginInfo.Mobile}}
      </el-descriptions-item>
      <el-descriptions-item >
        <template slot="label">
          <i class="el-icon-user-solid"></i>
          姓名
        </template>
        {{LoginInfo.OperatorMan}}
      </el-descriptions-item>
      <el-descriptions-item >
        <template slot="label">
          <i class="el-icon-connection"></i>
          公众号
        </template>
        {{LoginInfo.isbandwx}}
      </el-descriptions-item>
      
    </el-descriptions></el-card>
  <el-form :size="size" :inline="true" style="margin-top:15px;">
    <el-button type="success"  :size="size" icon="el-icon-document-add" @click="showbandPrmit=true">绑定使用证</el-button>
  </el-form>
    <div v-for="item in Prmitlist" :key="item.recid">
      <el-card style="margin-top:15px;background: #f9ffff;" >
      <el-descriptions  :title="'使用证：'+item.m_syzbh" :column="2" :size="size" border 
      label-class-name="my-label" content-class-name="my-content">
      <template slot="extra">
        <el-form size="small" :inline="true" >
          <el-button type="success" size="small" icon="el-icon-delete-solid" plain @click="deletebandPermit(item)">解绑</el-button>
        </el-form>
      </template>
      <el-descriptions-item >
        <template slot="label">
          <i class="el-icon-user-solid"></i>
         姓名
        </template>
        {{item.Contact}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          {{item.Mobile}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label">
            <i class="el-icon-location"></i>
            地址
          </template>
          {{item.ContactAddr}}
        </el-descriptions-item>
      </el-descriptions>
      </el-card>
      
    </div>
  </div>
    <el-dialog
      title="绑定手机号"
      :visible.sync="showbandMobile" 
      customClass="dgcustomClass" append-to-body
      :close-on-click-modal="false" :before-close="handleClose"
    >
        <el-form ref="bandMobile" label-width="10px" :model="LoginInfo" :size="size" >
          <el-form-item>
            <el-input
              placeholder="输入手机号"
              prefix-icon="el-icon-mobile-phone"
              v-model="LoginInfo.Mobile" clearable
              maxlength="15"
              auto-complete="off"
            >
            </el-input>
          </el-form-item>
            
          <div style="display: flex;align-items: center;">       
            <el-form-item>
              <el-input  placeholder="输入验证码" prefix-icon="el-icon-edit"
                v-model="LoginInfo.vcode" show-password clearable maxlength="8"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="button" type="success" plain :disabled="mobilecount==0?false:true"
                @click="getvcode('mobile')">{{mobilecount==0?"获取验证码":"剩余"+mobilecount+"秒"}}</el-button
              >
            </el-form-item>
          </div>   
          <el-form-item>
            <el-input
              placeholder="输入姓名"
              prefix-icon="el-icon-user-solid"
              v-model="LoginInfo.OperatorMan" clearable
              maxlength="15"
              auto-complete="off"
            >
            </el-input>
          </el-form-item>

        </el-form>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;right:20px;">
        <el-button :size="size" @click="handleClose">取 消</el-button>
        <el-button :size="size" type="primary" @click="bandMobile" 
          >绑 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="showeditPass" 
      customClass="dgcustomClass" append-to-body
      :close-on-click-modal="false" :before-close="handleClose"
    >
        <el-form label-width="10px" :model="LoginInfo" :size="size" >
          <el-form-item>
            <el-input
              placeholder="输入手机号"
              prefix-icon="el-icon-mobile-phone"
              v-model="LoginInfo.Mobile" clearable
              maxlength="15" :disabled="true"
              auto-complete="off"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              placeholder="输入旧密码"
              prefix-icon="el-icon-s-goods"
              v-model="LoginInfo.password" clearable
              maxlength="8"
              auto-complete="off"
            >
            </el-input>
          </el-form-item>            
            
          <el-form-item>
            <el-input
              placeholder="输入新密码"
              prefix-icon="el-icon-edit"
              v-model="LoginInfo.vcode" clearable
              maxlength="8"
              auto-complete="off"
            >
            </el-input>
          </el-form-item>

        </el-form>
      <span slot="footer" class="dialog-footer" style="margin-right:20px;right:20px;">
        <el-button :size="size" @click="handleClose">取 消</el-button>
        <el-button :size="size" type="primary" @click="editPassword" 
          >确定修改</el-button
        >
      </span>
    </el-dialog>
    <el-dialog 
      title="绑定微信"
      :visible.sync="showbandWeixin" append-to-body
      :close-on-click-modal="false" :before-close="handleClose"
    >
       <div style="width:100%;height:100%;margin:0px auto;display: flex;flex-direction: column;justify-content: center;align-items: center;">
         <canvas id="canvasweixin"></canvas>
				<div style="margin-bottom: 20px;line-height: 60rpx;" >打开微信扫一扫，扫描二维码绑定</div>
        </div> 
      
    </el-dialog>
    <el-drawer
      :visible.sync="showbandPrmit"
      direction="rtl" append-to-body :wrapperClosable="false"
      custom-class="drawer-prmit" :size="isPc?'25%':'85%'"
      ref="drawerPrmit"
      >
      <div class="demo-drawer__content">
      <div style="height:40px;margin-left:20px;color:#666;">使用证绑定方式</div>
      <div style="background-color:#FFFFFF;">
        <el-radio-group v-model="radioselect" style="margin:12px;">
          <el-radio label="1" style="margin:8px;">按使用证信息绑定</el-radio>
          <el-radio label="2" style="margin:8px;">按短信验证绑定</el-radio>
        </el-radio-group>
      </div>
      <div v-if="radioselect=='1'">
        <div style="margin:20px 0px;padding:10px 25px;background-color:#FFFFFF;">
          <div style="font-size: 13px;color:#999;">
            如果你还没有使用证，请到附近瀛海站点实名办理。</div>
        </div>
        <div style="padding:15px 20px 1px 10px;background-color:#FFFFFF;">
          <el-form :model="prmitForm" >
            <el-form-item label="使用证编号" label-width="90px" >
              <el-input  autocomplete="off" v-model="prmitForm.prmitno" maxlength="10" clearable></el-input>
            </el-form-item>
            <el-form-item label="名称或电话" label-width="90px" >
              <el-input  autocomplete="off" v-model="prmitForm.cmemo" maxlength="20" clearable></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div v-else>
        <div style="margin:20px 0px;padding:10px 25px;background-color:#FFFFFF;">
          <div style="font-size: 13px;color:#999;">需要此手机号在瀛海实名登记过使用证。如果你还没有使用证，请到附近瀛海站点实名办理。</div>
        </div>
        <div style="padding:15px 20px 1px 10px;background-color:#FFFFFF;">
          <el-form :model="prmitForm" >
            <el-form-item label="手机号码" label-width="90px" >
              <el-input  autocomplete="off" v-model="prmitForm.mobile" maxlength="11" clearable></el-input>
            </el-form-item>
            <div style="display: flex;align-items: center;">
            <el-form-item label="验证码" label-width="90px" >
              <el-input  autocomplete="off" v-model="prmitForm.vcode" maxlength="4" clearable
              ></el-input></el-form-item><el-form-item>
              <el-button class="button" type="success" plain :size="size" :disabled="prmitcount==0?false:true" style1="float: right;"
              @click="getvcode('prmit')">{{prmitcount==0?"获取验证码":"剩余"+prmitcount+"秒"}}</el-button>
            </el-form-item>
            </div>
            <!-- <el-form-item label="用户名称" label-width="90px" >
              <el-input  autocomplete="off" v-model="prmitForm.cname" maxlength="30" clearable></el-input>
            </el-form-item>
            <el-form-item label="身份证号" label-width="90px" >
              <el-input  autocomplete="off" v-model="prmitForm.sfz" maxlength="18" clearable></el-input>
            </el-form-item>
            <el-form-item label="送气地址" label-width="90px" >
              <el-input  autocomplete="off" v-model="prmitForm.address" maxlength="200" clearable></el-input>
            </el-form-item> -->
          </el-form>
        </div>
      </div>
      <div class="demo-drawer__footer" style="margin:20px">
          <el-button type="primary" @click="addPermit" :size="size" style="float: right;margin-right:5px;">确 定</el-button>
          <el-button @click="showbandPrmit=false" :size="size" style="float: right;margin-right:25px;">取 消</el-button>
      </div>
      
    </div>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import QRCode from "qrcode";
//import sfutil from '../../Api/identity.js';
export default {
  data() {
    return {
      formLabelWidth1:'80px',
      mobilecount:0,
      prmitcount:0,
      bannerHeight:540,
      size: 'medium',//medium,small,mini,
      divwidth:600,
      infocol:2,
      isPc:false,
      showbandMobile:false,
      showbandWeixin:false,
      showeditPass:false,
      showbandPrmit:false,
      LoginInfo:{},
      Prmitlist:[],
      prmitForm: {
        prmitno: '',
        cmemo: '',
        mobile:'',
        vcode:'',
      },
      radioselect:"1",
      prmitcid:'',
      mobilecid:'',
      mytimers:[],//定时器数组，便于清理
    }
  },
  beforeDestroy() {
    this.myclearTimer();
  },
  methods: {
    drawerClose(done){
      //done();
      this.showbandPrmit=false;
    },
    initMemuCollapse() {
      let screenWidth = document.body.clientWidth;
      //let screenWidth = document.body.clientHeight;
      if (
        (screenWidth <= 620 && this.isWideScreen) ||
        (screenWidth > 620 && !this.isWideScreen)
      ) {
        this.isWideScreen = !this.isWideScreen;
      }
      let p = navigator.platform;
      let u = navigator.userAgent;
      let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      //console.log(p,u);
      if (is_android) {
        //Android终端
        this.isPc = false;
      } else if (is_ios) {
        //IOS终端
        this.isPc = false;
      } else if (
        p == "Win32" ||
        p == "Win64" ||
        p == "MacPPC" ||
        p == "MacIntel" ||
        p == "X11" ||
        p == "Linux i686"
      ) {
        this.isPc = true;
      } else {
        this.isPc = false;
      }
      //console.log(this.isPc?'pc环境':'Mobile环境');
    },
    handleClose(){
      this.LoginInfo.vcode="";
      this.LoginInfo.password="";
      this.showbandMobile=false;
      this.showbandWeixin=false;
      this.showeditPass=false;
    },
    initDivSize() {
      this.isPc=this.$store.getters.GettabBarInfo("isPc");
      let u = navigator.userAgent;
      let is_WX=u.indexOf("micromessenger") > -1;
      let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      let imgcol = this.$refs.container.offsetWidth;
      if (is_WX || is_android || is_ios || imgcol<700){
        this.divwidth=imgcol-21;
        this.infocol=1;
      }else{
        this.divwidth=700-21;
        this.infocol=2;
      }
      //console.log(imgcol,this.divwidth);
    },
    bandMobile(){
      let that = this;
      let mobile = that.LoginInfo.Mobile||"";
      if (mobile && !that.$api.checkMobile(mobile)) {
        that.$message.error("手机号有误");
        return;
      }
      let vcode=that.$api.myFilter(that.LoginInfo.vcode);
      if (vcode.length>0){
        if (that.mobilecid==""){
          that.$message.error("请先获取验证码");
          return;
        }
        if (vcode.length != 4) {
          that.$message.error("输入有误，验证码是4位数字");
          return;
        }
      }
      let cname=that.$api.myFilter(that.LoginInfo.OperatorMan);
      if (cname.length>15){
          that.$message.error("输入有误，姓名太长");
          return;
        }
      
      let data = {
        procext:"yhjj",
        lx: "savebandMobile",
        sqlone: mobile,
        sqltwo: cname,
        pageno:vcode,
        pagesize:that.mobilecid,
      };
      that.$api.post(
        "",
        data,
        (success) => {
          that.$message.success(success["message"]);
          if (success["status"] == "1") {
            that.$parent.$parent.$parent.swapToken();
            that.getPermitList();
            that.showbandMobile=false;
            this.mytimers.push(setTimeout(() => {
              that.LoginInfo=JSON.parse(sessionStorage.getItem("LoginInfo"));
            }, 1800));
          }
        },
        (failure) => {
          //this.showUserInfo = false;
          that.$message.error(failure);
        }
      );
    },
    Again() {
      if (!this.showbandWeixin){return;}
      let data = {
        lx: "getscanweixin",
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] === "1") {
              if (res["scaned"]=="1"){
                this.$parent.$parent.$parent.swapToken();
                this.getPermitList();
                this.showbandWeixin=false;
                this.mytimers.push(setTimeout(() => {
                  this.LoginInfo=JSON.parse(sessionStorage.getItem("LoginInfo"));
                }, 1800));
              }else{
                this.mytimers.push(setTimeout(() => {
                  this.Again();
                }, 1500));
              }
              return;
          }
        },
        (failure) => {}
      );
    },
    bandWeixin(){
      this.showbandWeixin = true;
      let data = {
        lx: "makescanweixin"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.mytimers.push(setTimeout(() => {
              let canvaswei = document.getElementById("canvasweixin");
              let t=sessionStorage.getItem("userToken");
              QRCode.toCanvas(canvaswei, "http://www.yh59481717.com/yhweb/yhwebwx.aspx?o=3&t="+t, function (error) {
                if (error) {
                  this.$message.error(error);
                  return;
                }
              });
            }, 500));
             this.mytimers.push(setTimeout(() => {
                this.Again();
             }, 3000));
          } else {
            this.showbandWeixin = false;
            this.$message.error(res["message"]);
          }
        },
        (failure) => {
          this.showbandWeixin = false;
          this.$message.error(failure);
        }
      );
    },
    editPassword(){
      let that = this;
      let mobile = that.LoginInfo.Mobile||"";
      if (mobile.length==0 || !that.$api.checkMobile(mobile)) {
        that.$message.error("手机号有误");
        return;
      }
      let vcode=that.$api.myFilter(that.LoginInfo.vcode);
      let password=that.$api.myFilter(that.LoginInfo.password);
      if (vcode.length<4 || password.length<4 || password.length>8){
          that.$message.error("新旧密码输入有误");
          return;
      }
     
      let data = {
        procext:"yhjj",
        lx: "savenewpassword",
        sqlone: mobile,
        sqltwo: password,
        pageno:vcode
      };
      that.$api.post(
        "",
        data,
        (success) => {
          that.$message.success(success["message"]);
          if (success["status"] == "1") {
            that.handleClose();
          }
        },
        (failure) => {
          that.$message.error(failure);
        }
      );
    },
    deletebandPermit(item){
      let that = this;
      that.$confirm("真的要解绑吗?"+item.m_syzbh, "确认操作", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",//
          iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            procext:"yhjj",
            lx: ("deletesuepermit"),
            sqlone: item.recid,
          };
          that.$api.post("",data,
            (res) => {
              that.$message.success(res["message"]);
              if (res["status"] == "1") {
                that.getPermitList();
                this.$store.commit("SetRefrshItem","PrmitlistRefr");
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              that.$message.error(failure);
            }
          );
        })
      .catch(() => {});
    },
    addPermit(){
      let that = this;
      let tdata={};
      if (that.radioselect=="1"){
        let prmitno=that.$api.myFilter(that.prmitForm.prmitno.trim());
        if (prmitno.length>9 || prmitno.length<7) {
          that.$message.error("使用证编号输入有误");
          return;
        }
        let cmemo=that.$api.myFilter(that.prmitForm.cmemo.trim());
        if (cmemo.length>20 || prmitno.length<2) {
          that.$message.error("名称或电话输入有误");
          return;
        }
        tdata.prmitno=prmitno;
        tdata.cmemo=cmemo;
      }else{
        if (that.prmitcid==""){
            that.$message.error("请先获取验证码");
            return;
          }
        let mobile = that.$api.myFilter(that.prmitForm.mobile.trim());
        if (!that.$api.checkMobile(mobile)) {
          that.$message.error("手机号有误");
          return;
        }
        let vcode=that.$api.myFilter(that.prmitForm.vcode.trim());
        if (vcode.length != 4) {
          that.$message.error("输入有误，验证码是4位数字");
          return;
        }
        tdata.mobile=mobile;
        tdata.vcode=vcode;
        // let cname=that.$api.myFilter(that.prmitForm.cname.trim());
        // if (cname.length<2 || cname.length>30){
        //   that.$message.error("用户名称不能为空或太长");
        //   return;
        // }
        // let sfz=that.$api.myFilter(that.prmitForm.sfz.trim());
        // let sfzid=sfutil.SFID(sfz);
        // if (sfzid){
        //   that.$message.error(sfzid);
        //   return;
        // }
        // let address=that.$api.myFilter(that.prmitForm.address.trim());
        // if (address.length<1 || cname.length>200){
        //   that.$message.error("送气地址不能为空或太长200");
        //   return;
        // }
      }
      let data = {
        procext:"yhjj",
        lx: (that.radioselect=="1"?"bandpermit":"bandpermitmobile"),
        sqlone: (that.radioselect=="1"?tdata.prmitno:tdata.mobile),
        sqltwo: (that.radioselect=="1"?tdata.cmemo:tdata.vcode),
        pageno:that.radioselect,
        pagesize:that.prmitcid
      };
      that.$api.post("",data,
        (success) => {
          that.$message.success(success["message"]);
          if (success["status"] == "1") {
            that.getPermitList();
            this.$store.commit("SetRefrshItem","PrmitlistRefr");
            that.showbandPrmit=false;
          }
        },
        (failure) => {
          //this.showUserInfo = false;
          that.$message.error(failure);
        }
      );

    },
    getPermitList(){
      var that = this
      let data = {
        lx: "getusepermitlist",
        procext:"yhjj"
      };
      that.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            this.Prmitlist=res["records"];
          }
        },
        (failure) => {}
      );
    },
    //获取验证码
    getvcode(source) {
      let that = this;
      let mobile = that.LoginInfo.Mobile;
      if (source=="prmit")
        mobile=that.prmitForm.mobile;
      if (!that.$api.checkMobile(mobile)) {
        that.$message.error("手机号有误");
        return;
      }
          let data = {
            lx: "getverifycode",
            sqlone: mobile,
            sqltwo: source,
          };
          that.$api.post(
            "",
            data,
            (success) => {
              that.$message.success(success["message"]);
              if (success["status"] !== "1") {
                //that.LoginInfo.Mobile = mobile;
                if (source=='prmit') {
                  that.prmitcid=success["cid"];
                  that.prmitcount=120;
                  that.downcountp();
                  }
                if (source=='mobile') {
                  that.mobilecid=success["cid"];
                  that.mobilecount=120;
                  that.downcountm();
                  }
              }
            },
            (failure) => {
              //this.showUserInfo = false;
              that.$message.error(failure);
            }
          );
        
    },
    downcountm(){
      if (this.mobilecount>1 && this.mobilecount<121){
        this.mobilecount--;
        this.mytimers.push(setTimeout(() => {
            this.downcountm();
          }, 1000));
      }else{this.mobilecount=0}
    },
    downcountp(){
      if (this.prmitcount>1 && this.prmitcount<121){
        this.prmitcount--;
        this.mytimers.push(setTimeout(() => {
            this.downcountp();
          }, 1000));
      }else{this.prmitcount=0}
    },
    myclearTimer(){
      this.mytimers.forEach(e=>{
        if (e) clearTimeout(e)
      })
      this.mytimers=[];
    },
  },
  created() {
    let logininfo = JSON.parse(sessionStorage.getItem("LoginInfo")) || "";
    if (logininfo) {
      this.LoginInfo=logininfo;
    }
    this.LoginInfo.vcode="";
    this.LoginInfo.password="";
    this.getPermitList();
    console.log(this.LoginInfo)
    //this.$parent.$parent.$parent.swapToken();
    //this.$parent.$parent.$parent.LoginInfo.OperatorMan="VIP";
  },
  mounted() {
    window.onresize = () => {
      this.initDivSize();
    };
    this.initDivSize();
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  /* background-color: #a4c7f5; */
}
.el-dialog{
    min-width:320px !important;
}
.el-descriptions__header{
  margin-bottom:10px !important; 
}    
.my-label{
  width:22%;
  max-width:150px;
  min-width:80px;
  white-space: nowrap;
}

.my-content{
  min-width:100px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.input1 {
  width: 200px;
}
.button {
  width: 100px;
  margin-left: 20px;
}
.drawer-prmit{
  width:70%;
  max-width:700px;
  min-width:300px;
  background-color:rgb(240, 240, 240)
}
  </style>

